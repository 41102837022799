/*
 * Manages the responsive site-navigation menus. A horizontal navigation-menu is
 * used when viewport wider than the breakpoint, and a vertical, hamburger menu
 * is used otherwise.
 *
 * Export
 *     initSiteNav
 *
 *  TOC
 *     INIT NAVIGATION
 *     TABBED MENU
 *     HAMBURGER MENU
 *         INIT
 *         TOGGLE
 */
/** Module scoped data. */
const mScope = {
    breakpoint: 748,  /* between collapsed and expanded navigation */
    updateInProgress: false    /* present when window is being resized. */
};
/* ===================== INIT NAVIGATION ==================================== */
export function initSiteNav (): void {
    initViewportChangeListeners();
    toggleNavigationMenu();
}
function initViewportChangeListeners (): void {
    window.addEventListener( 'resize', toggleNavigationMenu );
    window.addEventListener( "orientationchange", toggleNavigationMenu );
}
/**
 * A horizontal navigation-menu is used when viewport wider than the breakpoint,
 * and a vertical, hamburger, menus is used otherwise.
 */
function toggleNavigationMenu (): void {
    if ( window.outerWidth >= mScope.breakpoint ) {
        initTabbedMenu();
    } else {
        initHamburgerMenu();
    }
}
/* ======================== TABBED MENU ===================================== */
function initTabbedMenu (): void {
    window.setTimeout( () => {
        mScope.updateInProgress = true;
        loadTabbedMenu();
        mScope.updateInProgress = false;
    }, 500 );
}
function loadTabbedMenu (): void {
    initTabbedStyles();
    unsetHamburgerListeners();
}
function initTabbedStyles (): void {
    $( '#mobile-menu-bar' ).hide();
    $( '#site-name' ).show();
    $( 'nav' ).addClass( 'flex-row' );
    $( '#oimenu' ).addClass( 'flex-row' );
    $( '#oimenu' ).removeClass( 'vert closed' );
    $( '#hdrmenu' ).removeClass( 'vert' );
    $( '#oimenu ul' ).css( 'position', 'absolute' );
    $( '.toggle' ).css( 'height', '0' );
}
/* ======================= HAMBURGER MENU =================================== */
function initHamburgerMenu (): void {
    window.setTimeout( () => {
        mScope.updateInProgress = true;
        loadHamburgerMenu();
        mScope.updateInProgress = false;
    }, 500 );
}
function loadHamburgerMenu (): void {
    initHamburgerStyles();
    setHamburgerListeners();
}
/* --------------------------- INIT ----------------------------------------- */
/* Hide tabbed menu beneath hamburger-menu icon. */
function initHamburgerStyles (): void {
    $( 'nav' ).removeClass( 'flex-row' );
    $( '#site-name' ).hide();
    $( '#mobile-menu-bar' ).css( 'display', 'flex' );
    $( '#hdrmenu' ).addClass( 'vert' );
    $( '#oimenu' ).addClass( 'vert closed' );
    $( '.toggle' ).css( 'height', 'fit-content' );
    $( '#oimenu ul' ).css( 'position', 'relative' );
}
function setHamburgerListeners (): void {
    $( '.toggle' ).on( 'click', toggleMenu );
    $( 'li.smtrigger' ).on( 'click', toggleSubMenu );
    $( 'li.smtrigger ul a' ).on( 'click', ( e ) => e.stopPropagation() );
}
function unsetHamburgerListeners (): void {
    $( '.toggle' ).off( 'click', toggleMenu );
    $( 'li.smtrigger' ).off( 'click', toggleSubMenu );
}
/* --------------------------- TOGGLE --------------------------------------- */
function toggleMenu (): void {
    const toggle = $( '#oimenu' ).hasClass( 'closed' ) ? showMobileMenu : hideMobileMenu;
    toggle();
}
function showMobileMenu (): void {
    $( '#oimenu' ).removeClass( 'closed' );
    $( '#content-detail' ).addClass( 'content-overlay' ).on( 'click', toggleMenu );
    $( '#page-headline' ).css( 'background', 'black' );
    $( '#entity-csv' ).hide(); //entity-show pages
    if ( $( 'body' ).data( 'browser' ) === 'Chrome' ) {
        $( '#content-detail' ).css( 'position', 'unset' );  //"fixes" bug with mobile menu in chrome that causes the content to jarringly jump back to the top
    }
}
function hideMobileMenu (): void {
    $( '#oimenu' ).addClass( 'closed' );
    $( '#content-detail' ).removeClass( 'content-overlay' ).off( 'click', toggleMenu );
    $( '#page-headline' ).css( 'background', '#fff' );
    $( '#entity-csv' ).show(); //entity-show pages
    if ( $( 'body' ).data( 'browser' ) === 'Chrome' ) {
        $( '#content-detail' ).css( 'position', 'absolute' ); //"fixes" bug with mobile menu in chrome
    }
}
function toggleSubMenu ( this: HTMLElement ): void {
    if ( $( this ).hasClass( 'closed' ) ) {
        $( 'li.smtrigger' ).addClass( 'closed' ); // closes all open sub-menus
        $( this ).removeClass( 'closed' );
        this.scrollIntoView( { behavior: "smooth", block: "nearest", inline: "nearest" } );
    } else {
        $( this ).addClass( 'closed' );
    }
}