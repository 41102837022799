/*
 * Handles sizing the image-slider in the site-header.
 *
 * Export
 *     initSlider
 *     resetContentAndSliderHeight
 */
/* Present during window resizing. */
let resizing: boolean;

export function initSlider (): void {
    initSliderContainerStyles();
    setSlideInterval();
    window.addEventListener( 'resize', resetContentAndSliderHeight );
}
export function resetContentAndSliderHeight (): void {
    if ( resizing ) { return; }
    resizing = true;
    window.setTimeout( () => {
        setSliderAndContentSize();
        resizing = false;
    }, 2100 );
}
function initSliderContainerStyles (): void {
    window.setTimeout( () => {
        setSliderAndContentSize();
    }, 700 );
}
/**
 * Sets slider height based on absolute positioned child image.
 * On mobile, sets the content blocks' tops value (header logo plus menu height)
 */
function setSliderAndContentSize (): void {
    const imgHeight = getImgSliderHeight();
    if ( imgHeight ) { //non-mobile devices
        $( '#img-slider' ).css( 'height', imgHeight );
        $( '#slider-logo' ).css( 'height', ( imgHeight / 2 ) );
    }
    $( '#content-detail' ).css( 'top', getContentTopPosition( imgHeight ) );
    $( '#slider-logo' ).fadeTo( 'slow', 1 );
}
function getImgSliderHeight (): number {
    return isSliderHidden() ? 0 : $( '#img-slider img:nth-child(1)' ).outerHeight() ?? 0;
}
function isSliderHidden (): boolean {
    const slide = $( '#img-slider' )[0];
    return !!( slide && window.getComputedStyle( slide ).display === "none" );
}

function getContentTopPosition ( imgHeight: number ): number {
    return imgHeight ? ( imgHeight + 86 ) : getMobileTopValue();
}
/** Note: #slider-overlay used for non-chrome browsers. */
function getMobileTopValue (): number {
    const containerH = $( '#slider-overlay' ).outerHeight() ?? 0;
    const logoH = $( '#slider-logo' ).outerHeight() ?? 0;
    const stickyH = $( '#sticky-hdr' ).outerHeight() ?? 0;
    return ( containerH || logoH ) + stickyH;
}
function setSlideInterval (): void {
    let curSlide = 1,
        nxtSlide = 2;
    window.setInterval( () => {
        $( `#img-slider img:nth-child(${ nxtSlide })` ).css( { opacity: 1 } );
        window.setTimeout( () => {
            $( `#img-slider img:nth-child(${ curSlide })` ).css( { opacity: 0 } );
            curSlide = nxtSlide;
            nxtSlide = curSlide === 3 ? 1 : curSlide + 1;
        }, 1000 );
    }, 10000 );
}