/**
 * Initializes the core UI for BatBase.org
 *
 * TOC
 *    GLOBALS AND STYLES
 *    AUTH-DEPENDENT INIT
 *    PAGE SPECIFIC
 */
import { _u, initUtil } from '~util';
import initFeedbackUi from './feedback/feedback';
import initHeaderAndNav from './header/site-header-main';
import initDataTable from './misc/data-table';
import showOverlayOnMobile from './misc/mobile-block';
import initSentryIssueTracking from './misc/sentry-init';
import initTogglePassword from './misc/toggle-password-visibility';
import initTos from './misc/tos';
import initWysiwyg from './misc/wysiwyg';

export function initSiteCore (): void {
    initSentryIssueTracking();
    setGlobalJquery();
    initUi();
    initUtil();
}
function initUi (): void {
    requireStyles();
    initHeaderAndNav();
    initTos();
    handlePageSpecificUiInit();
    authDependentInit();
}
/* ====================== GLOBALS AND STYLES ================================ */
//todo: "Not sure why this isn't working"
// declare global {
//     interface Window {
//         $: JQuery;
//         jQuery: JQuery;
//     }
// }
/**
 * Necessary for access during testing, in browser development tools, and third-party
 * access generally.
 */
function setGlobalJquery (): void {
    //@ts-expect-error "Not sure why this isn't typed correctly"  //todo
    global.$ = $;
    //@ts-expect-error "Not sure why this isn't typed correctly"
    global.jQuery = $;
}
function requireStyles (): void {
    require( 'styles/base/reset.styl' );
    require( 'styles/ei.styl' );
    require( 'intro.js/introjs.css' );
}
/* ==================== AUTH-DEPENDENT INIT ================================= */
function authDependentInit (): void {
    const userRole = $( 'body' ).data( "user-role" );
    if ( userRole === 'visitor' ) { return; }
    initFeedbackUi();
    if ( ifUserCanEditPage( userRole ) ) { initWysiwyg( userRole ); }
}
function ifUserCanEditPage ( userRole: string ): boolean {
    return userRole === 'admin' && window.outerWidth > 550 || userRole === 'super';
}
/* ========================== PAGE SPECIFIC ================================= */
function handlePageSpecificUiInit (): void {
    initDataTable();
    initTogglePassword();
    if ( !_u.isAdminUser() ) { showOverlayOnMobile(); }
}