/**
 * For pages that are not able to be used on mobile devices, show a popup directing
 * users to view page on a computer.
 */
export default function showOverlayOnMobile (): void {
    if ( $( 'body' ).data( 'env' ) == 'test' ) { return; }
    const mblMsg = getMobileMsg();
    if ( mblMsg ) { showMobilePopupMsg( mblMsg ); }
}
function getMobileMsg (): string | null {
    const winWidth = Math.round( getPageWidth() );
    const breakpoint = getPageWidthBreakpoint();
    return breakpoint && winWidth <= breakpoint ?
        getMobileMsgHtml( winWidth, breakpoint ) : null;
}
function getPageWidth (): number {
    return window.visualViewport ? window.visualViewport.width : window.innerWidth;
}
function getPageWidthBreakpoint (): number {
    const map = { search: 1200, 'view-pdfs': 800, feedback: 800 } as const;
    const path = window.location.pathname;
    const pg = Object.keys( map ).find( pg => path.includes( pg ) ) as keyof typeof map;
    return map[pg];
}
function getMobileMsgHtml ( winWidth: number, minWidth: number ): string {
    return `<center><h2>Page must be viewed on screen at least ${ minWidth } pixels wide.<h2>
        <br><p>This screen is ${ winWidth } pixels wide.</p></center>`;
}
function showMobilePopupMsg ( mblMsg: string ): void {
    const overlay = $( '<div></div>' ).addClass( 'mobile-opt-overlay' );
    const popup = $( '<div></div>' ).addClass( 'popup' );
    $( popup ).html( mblMsg );
    $( overlay ).append( popup );
    $( '#detail-block' ).prepend( overlay );
    $( '#b-overlay-popup' ).fadeIn( 500 );
}