/**
 * Toggles visibility for password inputs inside of password elems.
 */
export default function initTogglePassword (): void {
    if ( !pageHasPasswordField( window.location.pathname ) ) return;
    addPasswordToggleFunctionality();
} function pageHasPasswordField ( path: string ): boolean {
    const pages = ['login', 'register', 'reset-password'];
    return !!( pages.find( p => path.includes( p ) ) );
}
function addPasswordToggleFunctionality (): void {
    $( 'input[type="password"]' ).each( ( i, el: HTMLElement ) => {
        setToggleListeners( el );
    } );
}
function setToggleListeners ( el: HTMLElement ): void {
    const icon = el.nextElementSibling as HTMLElement;
    if ( !icon ) return;
    $( icon ).on( 'click', () => togglePassword( el, icon ) );
}
function togglePassword ( input: HTMLElement, toggle: HTMLElement ): void {
    if ( !( input instanceof HTMLInputElement ) ) return;
    const hide = 'fa-eye-slash';
    const show = 'fa-eye';

    if ( $( toggle ).hasClass( hide ) ) {
        $( toggle ).removeClass( hide ).addClass( show );
        input.type = 'text';
    } else {
        $( toggle ).removeClass( show ).addClass( hide );
        input.type = 'password';
    }
}
