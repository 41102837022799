/**
 * Loads site statistics relevant to the current page.
 *
 * Export
 * 	   initHeaderStats
 *
 * TOC
 * 	   INIT STAT HEADER
 * 	   LOAD STAT HEADER
 *         PAGES
 */
import { _u } from '~util';
/* ======================== init STAT HEADER ================================ */
export function initHeaderStats (): void {
    const statTag = getStatTagForPage();
    if ( !statTag ) return;
    _u.sendAjaxQuery( { tag: statTag }, 'stats/', getPageStatLoader( statTag ) );
}
function getStatTagForPage (): string | null {
    const pageStatKeys: { [key: string]: string; } = {
        about: 'project',
        db: 'db',
        home: 'core',
        register: 'all',
        search: 'db'
    };
    const pg = getPageName( window.location.pathname.split( '/' ) );
    return pageStatKeys[pg] ?? null;
}
function getPageName ( pathPieces: string[] ): string {
    if ( pathPieces.indexOf( 'register' ) !== -1 ) return 'register';
    const pg = pathPieces.pop();
    return pg || 'home';
}
/* ======================== LOAD STAT HEADER ================================ */
type DataStats = {       //todo: handle optional stats
    bat?: string;
    cite?: string;
    int?: string;
    loc?: string;
    user?: string;
    editor?: string;
};
function getPageStatLoader ( statTag: string ): typeof loadHeaderData.all {
    const loadHeaderData = {
        all: loadAllDatabaseHeaderStats,
        core: loadCoreDatabaseHeaderStats,
        db: loadFullDatabaseHeaderStats,
        project: loadAboutProjectHeaderStats
    } as const;
    return loadHeaderData[statTag as keyof typeof loadHeaderData];
}
function setHeaderStats ( statString: string ): void {
    $( '#headline-stats' ).empty().append( statString );
}
function buildStatString ( order: string[], stats: DataStats, includeEst = false ): string {
    const strings = order.map( key => stats[key as keyof DataStats] );
    if ( includeEst ) strings.push( 'Est. 2002' );
    return strings.join( ' | ' );
}
/* -------------------------- PAGE STATS ------------------------------------ */
function loadCoreDatabaseHeaderStats ( stats: DataStats, _1: string, _2: string ): void {
    const order = ['int', 'bat', 'cite', 'loc'];
    const statTxt: string = buildStatString( order, stats );
    setHeaderStats( statTxt );
}
function loadAboutProjectHeaderStats ( stats: DataStats, _1: string, _2: string ): void {
    const order = ['user', 'editor'];
    const statTxt: string = buildStatString( order, stats, true );
    setHeaderStats( statTxt );
}
function loadFullDatabaseHeaderStats ( stats: DataStats, _1: string, _2: string ): void {
    const order = ['int', 'bat', 'nonBat', 'cite', 'loc'];
    const statTxt: string = buildStatString( order, stats );
    setHeaderStats( statTxt );
}
function loadAllDatabaseHeaderStats ( stats: DataStats, _1: string, _2: string ): void {
    const order = ['int', 'bat', 'nonBat', 'cite', 'loc', 'user', 'editor'];
    const statTxt: string = buildStatString( order, stats );
    setHeaderStats( statTxt );
}