/**
 * Inits automated issue-tracking, Sentry.
 */
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

/* ================= SENTRY ERROR TRACKING ================================== */
export default function initSentryIssueTracking (): void {
    if ( $( 'body' ).data( 'env' ) !== 'prod' ) { return; }
    initSentry();
}
/* --------------------- INIT SENTRY ---------------------------------------- */
function initSentry (): void {
    Sentry.init( {
        dsn: 'https://28ec22ce887145e9bc4b0a243b18f94f@o955163.ingest.sentry.io/5904448',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.2,  // sets a uniform sample rate
        denyUrls: ['dev.batbase.org'],
        beforeSend ( event, hint ): Sentry.Event | null {  /*Catches rouge 3rd-party error, likely google recaptcha  https://github.com/getsentry/sentry-javascript/issues/2514 */
            if ( hint && hint.originalException === "Timeout" ) return null;
            return event;
        }
    } );
    configureSentryUserData();
}
function configureSentryUserData (): void {
    const user = {
        role: $( 'body' ).data( 'user-role' ),
        username: $( 'body' ).data( 'user-name' ),
    };
    Sentry.configureScope( scope => {
        scope.setUser( user );
    } );
}