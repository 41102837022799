/**
 * Initializes the site header with the image slider, sticky menu, and any site-
 * statistics relevant to the current page. Due to the site being primarily tested
 * and developed with the Chrome browser, users accessing with a different browser
 * have additional optimization information displayed in the header.
 *
 * Export
 *     initHeaderAndNav
 *
 * TOC
 *     OPTIMIZED BROWSER WARNING
 *     HEADER AND MAIN-NAV
 */
import { initSiteNav } from './nav';
import { initHeaderStats } from './site-stats';
import { initSlider, resetContentAndSliderHeight } from './slider';
/**
 * Initializes the site header with the image slider, sticky menu, and any site-
 * statistics relevant to the current page. Due to the site being primarily tested
 * and developed with the Chrome browser, users accessing with a different browser
 * have additional optimization information displayed in the header.
*/
export default function initHeaderAndNav (): void {
    ifNonChromeBrowserShowWarning();
    initHeaderStats();
    initCoreHeaderElems();
}
/* ====================== OPTIMIZED BROWSER WARNING ========================= */
function ifNonChromeBrowserShowWarning (): void {
    if ( navigator.userAgent.indexOf( 'Chrome' ) !== -1 ) { return; }
    addMsgAboutChromeOptimization();
}
function addMsgAboutChromeOptimization (): void {
    const msg = buildMsgHtml();
    const logo = $( '#slider-logo' ).detach();
    $( logo ).addClass( 'overlay' );
    $( '#slider-overlay' ).css( 'padding', '2em' ).prepend( [msg, logo] );
    resetContentAndSliderHeight();
}
function buildMsgHtml (): HTMLDivElement {
    const div = document.createElement( "div" );
    div.id = 'chrome-opt-msg';
    div.innerHTML = `<b>This site is developed and tested with chrome.</b> If
        you encounter issues with other browsers, please log in and leave
        feedback to let us know.`;
    return div;
}
/* =================== INIT SITE-HEADER ===================================== */
function initCoreHeaderElems (): void {
    initSlider();
    initSiteNav();
    initStickyHeader(); //Must load after image slider and the nav menus
}
/* -------------------- STICKY MENU ----------------------------------------- */
/* Header-menu sticks to the top when image header scrolls off screen. */
function initStickyHeader (): void {
    const headerH = getInitialHeaderHeight();
    $( window ).on( 'scroll', handleStickyNav.bind( null, headerH ) );
    $( window ).trigger( 'scroll' );
}
function getInitialHeaderHeight (): number {
    return $( '#img-slider' ).outerHeight() ||
        $( '#slider-overlay' ).outerHeight() ||
        $( '#slider-logo' ).outerHeight() || 0;
}
function handleStickyNav ( headerH: number ): void {
    const scrollTop = $( window ).scrollTop();
    if ( scrollTop && scrollTop > headerH ) {
        $( '#sticky-hdr' ).addClass( "top-sticky" );
    } else {
        $( '#sticky-hdr' ).removeClass( "top-sticky" );
    }
}